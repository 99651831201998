import React from 'react';
import Layout from '../components/Layout/Layout';
import Header from '../components/Home/Header';
import Community from '../components/Home/Community/Community';
import CompanyHighlights from '../components/Home/CompanyHighlightList/CompanyHighlightList';
import Customers from '../components/Home/Customers';
import IterativeTools from '../components/Home/IterativeTools';
import LearnMore from '../components/Home/LearnMore';
import GetStarted from '../components/Home/GetStarted';
import ToolsSuite from '../components/Home/Suite';
import Footer from '../components/Footer/Footer';

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <main>
        <Customers />
        <IterativeTools />
        <CompanyHighlights />
        <LearnMore />
        <GetStarted />
        <ToolsSuite />
        <Community />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
