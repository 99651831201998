import React from 'react';
import SectionTitle from '../../SectionTitle/SectionTitle';
import Diagram from './Diagram';
import * as styles from './index.module.scss';

export default function Enterprise() {
  return (
    <section className={styles.section}>
      <SectionTitle
        title="Iterative AI Tools"
        subtitle="Supercharge collaboration and productivity across your ML teams. Iterative automates data science workflows across the entire ML model development lifecycle."
      />
      <Diagram />
    </section>
  );
}
