import React from 'react';
import Header from '../../Header/Header';
import PageTitle from '../../PageTitle/PageTitle';
import BgAnimation from '../../BgAnimation/BgAnimation';
import RequestDemo from '../../RequestDemo';
import * as styles from './index.module.scss';

function HomeHeader() {
  return (
    <Header>
      <PageTitle
        isMain
        title="ML Model Development for teams"
        text="Mission control for ML experiments, data, and compute"
      />
      <RequestDemo buttonProps={{ className: styles.button }} />
      <BgAnimation />
    </Header>
  );
}

export default HomeHeader;
