const products = [
  {
    description:
      'Collaborate across your ML team with experiment tracking and visualization',
    link: 'https://studio.iterative.ai/',
    image: 'studio',
    imageAlt: 'The Studio logo next to the text "Studio".',
  },
  {
    description:
      'Build models faster with data and experiment versioning and reproducible pipelines',
    link: 'https://dvc.org/',
    image: 'dvc',
    imageAlt: 'The DVC logo next to the text "DVC".',
  },
  {
    description: 'Train ML experiments using CI/CD automation across any cloud',
    link: 'https://cml.dev/',
    image: 'cml',
    imageAlt: 'The CML logo next to the text "CML".',
  },
  {
    description:
      'Simplify model deployments by extracting model meta data and building a model registry',
    link: 'https://mlem.ai/',
    image: 'mlem',
    imageAlt: 'The MLEM logo next to the text "MLEM".',
  },
];

export default products;
