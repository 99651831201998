import React from 'react';
import './CompanyHighlightList.scss';
import CompanyHighlight from '../CompanyHighlight/CompanyHighlight';
import handsIcon from '../../../images/icons/hands.svg';
import rocketIcon from '../../../images/icons/rocket.svg';
import spheresIcon from '../../../images/icons/spheres.svg';

export default function CompanyHighlightList() {
  return (
    <ul className="company-highlight-list">
      <CompanyHighlight
        icon={rocketIcon}
        title="Accelerate Time-to-Market"
        description="Manage and version data, experiments, and models across the entire ML lifecycle with instant reproducibility."
      />
      <CompanyHighlight
        icon={handsIcon}
        title="Improve Collaboration"
        description="Share knowledge with custom dashboards and encourage discussion around models."
      />
      <CompanyHighlight
        icon={spheresIcon}
        title="Centralize Visibility and Governance"
        description="Track progress of ML projects and ensure data and model lineage details for governance and regulatory concerns."
      />
    </ul>
  );
}
