import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './index.module.scss';

function Section({ title, imageAlt = '', text, image }) {
  return (
    <div className={styles.section}>
      {getImage(image) ? (
        <GatsbyImage
          className={styles.image}
          alt={imageAlt}
          image={getImage(image)}
        />
      ) : (
        <img
          className={styles.image}
          alt={imageAlt}
          src={image}
          width="450"
          height="300"
        />
      )}
      <div className={styles.texts}>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Section;

Section.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
};
