import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import Title from '../../../../Title/Title';
import { cases } from '../content';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as styles from './index.module.scss';

const Mobile = ({ onSetProducts }) => {
  const [titlesSlider, setTitlesSlider] = useState(null);
  const [textsSlider, setTextsSlider] = useState(null);
  const textsSliderRef = useRef(null);
  const titlesSliderRef = useRef(null);

  useEffect(() => {
    setTitlesSlider(titlesSliderRef.current);
    setTextsSlider(textsSliderRef.current);
  }, []);

  return (
    <div className={styles.mobileCases}>
      <Slider
        arrows={false}
        swipeToSlide
        className={styles.mobileCases__titles}
        variableWidth
        infinite={false}
        asNavFor={textsSlider}
        ref={titlesSliderRef}
      >
        {cases.map(({ title }, i) => (
          <Title
            title={title}
            size="s"
            sizeStyle="xs"
            className={styles.useCase__title}
            key={i}
          />
        ))}
      </Slider>
      <Slider
        arrows={false}
        swipeToSlide
        dots
        className={styles.mobileCases__texts}
        infinite={false}
        asNavFor={titlesSlider}
        ref={textsSliderRef}
        beforeChange={(_, next) => {
          onSetProducts(cases[next].products);
        }}
        onInit={() => {
          if (window.innerWidth < 768) {
            onSetProducts(cases[0].products);
          }
        }}
      >
        {cases.map(({ text }, i) => (
          <p key={i} className={styles.useCase__text}>
            {text}
          </p>
        ))}
      </Slider>
    </div>
  );
};

export default function Cases({ onSetActiveProducts, onCaseMouseLeave }) {
  return (
    <>
      <Mobile onSetProducts={onSetActiveProducts} />
      <ul className={styles.cases}>
        {cases.map(({ title, text, products }, i) => (
          <li
            onMouseLeave={onCaseMouseLeave}
            onMouseEnter={() => onSetActiveProducts(products)}
            key={i}
            className={styles.useCase}
          >
            <Title
              title={title}
              size="s"
              sizeStyle="xs"
              className={styles.useCase__title}
            />
            <p className={styles.useCase__text}>{text}</p>
          </li>
        ))}
      </ul>
    </>
  );
}
