import React, { useState } from 'react';
import Popup from '../Popup/Popup';
import Form from './Form';
import Button from '../Button/Button';
import './index.scss';
import { useRef } from 'react';

export default function RequestDemo({
  buttonText = 'Request a Demo',
  buttonProps,
  children,
}) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const wrapperRef = useRef(null);

  return (
    <>
      <Popup
        isVisible={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        ariaLabel="Request Demo Form"
        getContents={(close) => (
          <div ref={wrapperRef}>
            <Form close={close} />
          </div>
        )}
        className="requestDemoPopup"
        lockScroll={false}
        shards={[wrapperRef]}
      />
      {children ? (
        <button onClick={() => setIsPopupOpen(true)}>{children}</button>
      ) : (
        <Button onClick={() => setIsPopupOpen(true)} {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </>
  );
}
