import React from 'react';
import Title from '../../Title/Title';
import RequestDemoLink from '../../RequestDemo';
import BgAnimation from '../../BgAnimation/BgAnimation';
import * as styles from './index.module.scss';

export default function GetStarted() {
  return (
    <section className={styles.getStarted}>
      <Title size="m" title="Get started today for free" />
      <p>Mission control for ML experiments, data, and compute</p>
      <RequestDemoLink className={styles.getStarted__button} />
      <BgAnimation className={styles.getStarted__animation} />
    </section>
  );
}
