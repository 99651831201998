import React from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import { productInfo } from '../content';
import platoImg from '../../../../../images/diagram/plato.svg';
import { ReactComponent as AllProductsActive } from '../../../../../images/diagram/products-active.svg';
import { ReactComponent as AllProducts } from '../../../../../images/diagram/products.svg';
import { isBrowser } from '../../../../../utils/isBrowser';
import * as styles from './index.module.scss';

const getActiveClasses = (products) =>
  products.map((product) => styles[`diagram_activeProduct_${product}`]);

export default function Image({ activeProducts, onSetActiveProducts }) {
  return (
    <div className={cn(styles.diagram, getActiveClasses(activeProducts))}>
      <AllProductsActive
        className={cn(styles.products, styles.activeProducts)}
      />
      {Object.entries(productInfo).map(([productKey, { title, text }]) => (
        <ReactTooltip
          afterShow={() => onSetActiveProducts([productKey])}
          afterHide={() => onSetActiveProducts([])}
          className={styles.tooltipWrapper}
          id={`${productKey}-tooltip`}
          aria-haspopup="true"
          padding="0"
          backgroundColor="rgba(15, 22, 36, 0.75)"
          effect="solid"
          disable={isBrowser() && window.innerWidth < 768}
        >
          <div data-product={productKey} className={styles.tooltip}>
            <h3 className={styles.tooltip__title}>{title}</h3>
            <p className={styles.tooltip__text}>{text}</p>
          </div>
        </ReactTooltip>
      ))}
      <AllProducts className={cn(styles.products, styles.inActiveProducts)} />
      <img
        className={styles.plato}
        src={platoImg}
        alt=""
        width="650"
        height="375"
      ></img>
    </div>
  );
}
