import React from 'react';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import BaseLink from '../../../BaseLink/BaseLink';
import * as styles from './index.module.scss';

function Tier({ image, imageAlt = '', description, link }) {
  return (
    <li className={styles.tier}>
      {getImage(image) ? (
        <GatsbyImage
          className={styles.tier__img}
          alt={imageAlt}
          image={getImage(image)}
        />
      ) : (
        <img
          className={styles.tier__img}
          alt={imageAlt}
          src={image}
          width="450"
          height="300"
        />
      )}
      <p className={styles.tier__description}>{description}</p>
      <BaseLink className={styles.tier__link} href={link}>
        Learn more
      </BaseLink>
    </li>
  );
}

export default Tier;

Tier.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  imageAlt: PropTypes.string,
  link: PropTypes.string.isRequired,
};
