import React, { useEffect } from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import Spinner from './Spinner';
import Icon from '../../Icon/Icon';
import closeIcon from '../../../images/icn-close.svg';
import * as styles from './index.module.scss';

function RequestDemoForm({ close }) {
  useHubspotForm({
    portalId: '21087317',
    formId: '20e9b583-20ae-42b9-9ec5-136643fc5c31', // Use '9bed4ea2-927d-4f82-a548-6311264ed295' for form submission testing
    target: `.${styles.form}`,
    region: 'na1',
    submitButtonClass: styles.form__btn,
    errorClass: styles.form__input_invalid,
    errorMessageClass: styles.form__error_invalid,
  });

  const handler = (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted'
    ) {
      const isUserFromGoogleAds = Boolean(sessionStorage.getItem('google_ads'));
      if (isUserFromGoogleAds && window.gtag) {
        window.gtag('event', 'ga_request_demo_submission', {
          value: 1,
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  return (
    <div
      tabIndex={0}
      id={styles.formWrapper}
      className={close && styles.formWrapper_isPopup}
    >
      <h2>Request a demo</h2>
      {close && (
        <button onClick={close} className={styles.closeBtn}>
          <span className="sr-only">Close Popup</span>
          <Icon widthPixelSizes={[16, 24, 24]} icon={closeIcon} />
        </button>
      )}
      <div tabIndex={0} className={styles.form}>
        <Spinner />
      </div>
    </div>
  );
}

export default RequestDemoForm;
