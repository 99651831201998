export const cases = [
  {
    title: 'Manage data',
    text: 'Version data stored across any cloud and manage labels and annotations.',
    products: ['dvc', 'vscode'],
  },
  {
    title: 'Develop models',
    text: 'Develop models across the entire ML lifecycle with experiment versioning and tracking.',
    products: ['dvc', 'vscode', 'studio'],
  },
  {
    title: 'Train and deploy',
    text: 'Automate training on any cloud with live reports.',
    products: ['mlem', 'studio', 'tpi', 'cml'],
  },
  {
    title: 'Collaborate',
    text: 'Build and share dashboards of experiments and manage models from development to production and retirement.',
    products: ['mlem', 'studio', 'tpi', 'cml', 'dvc', 'vscode'],
  },
];

export const productInfo = {
  dvc: {
    title: 'DVC',
    text: 'Build models faster with data and experiment versioning and reproducible pipelines',
    link: 'https://dvc.org/',
  },
  mlem: {
    title: 'MLEM',
    text: 'Simplify model deployments by extracting model meta data and building a model registry',
    link: 'https://mlem.ai/',
  },
  cml: {
    title: 'CML',
    text: 'Train ML experiments using CI/CD automation across any cloud',
    link: 'https://cml.dev/',
  },
  studio: {
    title: 'Studio',
    text: 'Collaborate across your ML team with experiment tracking and visualization',
    link: 'https://studio.iterative.ai/',
  },
  tpi: {
    title: 'TPI',
    text: 'TPI is a Terraform plugin built with machine learning in mind.',
    link: 'https://github.com/iterative/terraform-provider-iterative',
  },
  vscode: {
    title: 'VS Code Extension',
    text: 'text text text text text text text text text text text text text text text text text text text text text text',
    link: 'https://dvc.org/',
  },
};
