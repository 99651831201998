const sections = [
  {
    title: 'Version control your data and ML experiments',
    text: 'Reproduce experiments and pipelines instantly with a single command Use any language, framework, cloud, and storage Version and manage all data and metrics using your Git as a single source of truth',
    image: 'versionControlExample',
    imageAlt: 'A 3d example of a graphical user interface.',
  },
  {
    title: 'Automate compute resources across any cloud',
    text: 'Automate provisioning of training resources for experiments Train models using any cloud or on-premises infrastructure with a single click Generate training reports automatically, stored as Git pull requests',
    image: 'cloudAutomationExample',
    imageAlt: 'A 3d example of two clouds with a wire inbetween',
  },
  {
    title: 'Track and manage your ML experiments',
    text: 'Share knowledge and create custom dashboards of models for your team Visualize and track experiments Re-run experiments without using command line',
    image: 'experimentTrackingExample',
    imageAlt: 'A 3d example of a piece of technology ',
  },
];

export default sections;
