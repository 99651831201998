import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SectionTitle from '../../SectionTitle/SectionTitle';
import sections from './content';
import Section from './Section';
import * as styles from './index.module.scss';

export default function Enterprise() {
  const images = useStaticQuery(query);

  return (
    <section className={styles.section}>
      <SectionTitle title="Learn more about our products" />
      <div>
        {sections.map(({ title, text, image, imageAlt }, i) => (
          <Section
            key={i}
            title={title}
            text={text}
            image={images[image]}
            imageAlt={imageAlt}
          />
        ))}
      </div>
    </section>
  );
}

const query = graphql`
  query getLearnMoreImages {
    versionControlExample: file(
      relativePath: { regex: "/version-control-example.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 520
          placeholder: BLURRED
          quality: 100
        )
      }
    }
    cloudAutomationExample: file(
      relativePath: { regex: "/cloud-computation-example.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 520
          placeholder: BLURRED
          quality: 100
        )
      }
    }
    experimentTrackingExample: file(
      relativePath: { regex: "/track-and-manage-experiments-example.png$/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 520
          placeholder: BLURRED
          quality: 100
        )
      }
    }
  }
`;
