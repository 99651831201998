import React from 'react';
import degouldLogo from '../../../images/customers/degould.png';
import piecesLogo from '../../../images/customers/pieces.png';
import plasmasolveLogo from '../../../images/customers/plasmasolve.png';
import ubsLogo from '../../../images/customers/ubs.png';
import ukhoLogo from '../../../images/customers/ukho.png';
import * as styles from './index.module.scss';

const logos = [
  { img: degouldLogo, mobileWidth: 66, alt: 'DeGould logo' },
  { img: ubsLogo, mobileWidth: 55, alt: 'UBS logo' },
  { img: piecesLogo, mobileWidth: 64, alt: 'Pieces logo' },
  { img: plasmasolveLogo, mobileWidth: 80, alt: 'PlasmaSolve logo' },
  { img: ukhoLogo, mobileWidth: 75, alt: 'United Kingdom Hydrographic Office' },
];

export default function Customers() {
  return (
    <div className={styles.customers}>
      <p className={styles.customersText}>
        Trusted by hundreds of ML teams, from startups to Fortune 500 companies:
      </p>
      <ul className={styles.customersList}>
        {logos.map(({ img, mobileWidth, alt }, i) => (
          <li
            style={{ '--img-width': `${mobileWidth}`, '--img-height': '36' }}
            key={i}
            className={styles.customersList__item}
          >
            <img width="70" height="40" src={img} alt={alt} />
          </li>
        ))}
      </ul>
    </div>
  );
}
