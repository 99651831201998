import React, { useState } from 'react';
import Image from './Image';
import Cases from './Cases';
import * as styles from './index.module.scss';

function Diagram() {
  const [activeProducts, setActiveProducts] = useState([]);

  return (
    <div className={styles.wrapper}>
      <Cases
        onSetActiveProducts={setActiveProducts}
        onCaseMouseLeave={() => setActiveProducts([])}
      />
      <Image
        onSetActiveProducts={setActiveProducts}
        activeProducts={activeProducts}
      />
    </div>
  );
}

export default Diagram;
