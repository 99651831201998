import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './CommunityCard.module.scss';

export default function CommunityCard({ title, description }) {
  return (
    <li className={styles.communitycard}>
      <h3 className={styles.communitycard__title}>{title}</h3>
      <p className={styles.communitycard__description}>{description}</p>
    </li>
  );
}

CommunityCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
