import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SectionTitle from '../../SectionTitle/SectionTitle';
import products from './content';
import Tier from './Tier';
import * as styles from './index.module.scss';

export default function Suite() {
  const images = useStaticQuery(query);

  return (
    <section className={styles.section}>
      <SectionTitle title="Iterative suite of tools" />
      <ul className={styles.products}>
        {products.map(({ image, link, description }, i) => (
          <Tier
            image={images[image] || image}
            link={link}
            description={description}
            key={i}
          />
        ))}
      </ul>
    </section>
  );
}

const query = graphql`
  query getLogos {
    studio: file(relativePath: { regex: "/logo-studio.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 50
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    cml: file(relativePath: { regex: "/logo-cml.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 50
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    dvc: file(relativePath: { regex: "/logo-dvc.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 50
          placeholder: BLURRED
          quality: 90
        )
      }
    }
    mlem: file(relativePath: { regex: "/logo-mlem.png$/" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          height: 50
          placeholder: BLURRED
          quality: 90
        )
      }
    }
  }
`;
